<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <trac-modal v-if="successModalCtrl" :cancel="false">
      <div class="flex flex-col justify-center items-center p-6 gap-6">
        <img src="./../../../assets/svg/pin-management/success-icon.svg" alt="" />
        <p class="text-sm">{{ successRes.message }}</p>

        <button data-test="done-button" class="border-none bg-none text-primaryBlue p-4 outline-none" @click="confirmSuccessPref">
          Done
        </button>
      </div>
    </trac-modal>


    <trac-back-button>Back</trac-back-button>

    <h3 class="my-8 font-bold text-sm">KYC(Settlement Preference)</h3>
    <div class="shadow-lg rounded-md p-4 pt-8 w-9/12 mx-auto my-8 px-12 py-16">
      <div class="w-5/12">
        <img src="./../../../assets/svg/pin-management/settlement-pref.svg" alt=""
          class="bg-accentLight p-5 rounded-md" />

        <h3 class="mb-1 mt-5 font-bold text-lg text-primaryBlue">
          How do you want to be settled?
        </h3>
        <p class="text-xs">Select how you would want to be settled.</p>

        <div class="
            mt-12
            mb-5
            flex
            flex-col
            items-start
            justify-between
            pb-2
            relative
            info-hold
          ">
          <div class="flex items-center gap-5 pb-4 border-b">
              <div  class="flex items-center gap-3">
                <!-- End of Day Settlement -->
                <div class="flex items-center gap-3">
                    <input
                      type="checkbox"
                      value="sameday"
                      :checked="settlementType === 'sameday'"
                      @change="updateSettlementType($event, 'sameday')"
                      data-test="settlement-preference"
                      id="end-of-day" />

                    <label for="end-of-day" class="text-xs font-semibold cursor-pointer">
                      End of the day settlement
                    </label>
                </div>
              </div>
            <div class="flex">
              <button v-if="settlementType === 'sameday'" @click="toggleDropdown" class="text-xs cursor-pointer focus:outline-none">
                <img
                  src="./../../../assets/svg/caret-down.svg"
                  alt=""
                  class="p-1 w-5 transition-transform duration-300 ease-in-out"
                  :class="{'caret-rotate': isDropdownOpen}"
                />
              </button>
              <img src="./../../../assets/svg/pin-management/info-icon.svg" alt="" class="info-icon ml-1" />
              <div class="
                  absolute
                  p-2
                  rounded-md
                  bg-backgroundArea
                  w-60
                  shadow-sm
                  info-card
                ">
                <p class="" style="">
                  At the end of each day, you will receive a settlement for all your
                  transactions carried out for the day before 10pm sent to your
                  settlement account.
                </p>
              </div>
            </div>
          </div>

          <!-- Dropdown section -->
          <div v-if="showDropdown" class="mt-4">
            <div class="flex flex-col gap-2">
              <div class="flex items-center gap-5">
                <input 
                  type="checkbox" 
                  value="business" 
                  :checked="samedayType.includes('business')"
                  @change="toggleSamedayType('business')" 
                  id="sameday-type-business" />
                <label for="sameday-type-business" class="text-xs cursor-pointer">
                  <div class="flex gap-2 items-center">
                    <img
                    src="./../../../assets/svg/pin-management/business-settlement-icon.svg"
                    alt=""
                    class="bg-accentLight p-2 rounded-md w-10 h-10 "
                    />
                    <div class="flex flex-col justify-between gap-1 mb-1 w-60 ">
                      <div class="mt-1 leading-3 font-bold">Business Settlement ( Default)</div>
                      <span class="text-xxs leading-tight font-light">Receive all your settlements in a single business account.</span>
                    </div>
                  </div>
                </label>
              </div>
              <div class="flex items-center gap-5">
                <input type="checkbox" value="multistore" :checked="samedayType.includes('multistore')"
                  @change="toggleSamedayType('multistore')" id="sameday-type-multistore" />
                <label for="sameday-type-multistore" class="text-xs cursor-pointer">
                  <div class="flex gap-2 items-center">
                    <img
                    src="./../../../assets/svg/pin-management/store-settlement-icon.svg"
                    alt=""
                    class="bg-accentLight p-2 rounded-md w-10 h-10 "
                    />
                    <div class="flex flex-col justify-between gap-1 mb-1 w-60 ">
                      <div class="mt-1 leading-3 font-bold">Store Settlement</div>
                      <span class="text-xxs leading-tight font-light">Receive settlements for each store in separate accounts.</span>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

      <!-- Instant Settlement -->
        <div :class="{ 'opacity-50': !walletId }" class="
            mt-8
            mb-5
            flex
            justify-between
            border-b
            pb-4
            items-center
            relative
            info-hold
          ">
          <div class="flex items-center gap-5">
            <div class="flex items-center gap-3">
              <input
                type="checkbox"
                value="instant"
                :checked="settlementType === 'instant'"
                @change="updateSettlementType($event, 'instant')"
                data-test="settlement-preference"
                id="instant-settlement"
                :disabled="!walletId" />
              <label for="instant-settlement" class="text-xs font-semibold cursor-pointer">Instant settlement</label>
            </div>

          </div>
          <img src="./../../../assets/svg/pin-management/info-icon.svg" alt="" class="info-icon w-2" />
          <div class="
              absolute
              p-2
              rounded-md
              bg-backgroundArea
              w-60
              shadow-sm
              info-card
            ">
            <p class="" style="">
              Get instant settlement of all your transactions immediately the
              transaction is carried out, into your wallet account.
            </p>
          </div>
        </div>

        <!-- CTA to Setup Wallet if not done -->
        <!-- Create an outline box with lorem text -->
        <div v-if="!walletId" class="mt-8 border border-blue-400 p-5 rounded-sm">
          <p class="text-xs">
            You need to setup your wallet to use this option. Click the button
            below to setup your wallet.
          </p>
          <trac-button class="mt-4"
            @button-clicked="$router.push({ name: 'ConfirmPinInput', query: { ops: 'new_pin', onboarding: true } })">Setup
            Wallet
          </trac-button>
        </div>
      </div>
      <div class="flex justify-end mt-10">
        <trac-button data-test="confirm-button" v-if="settlementType" @button-clicked="updateSettlementOption">
          CONFIRM
        </trac-button>
      </div>
    </div>
  </main>
</template>

<script>
import { eventBus } from "./../../../eventBus";

export default {
  data() {
    return {
      successModalCtrl: false,
      successRes: null,
      isLoading: false,
      settlementType: "",
      walletId: null,
      samedayType: [''],
      showDropdown: false,
      isDropdownOpen: false, 
    };
  },
  async created() {
    this.isLoading = true;
    const res = await this.$store.dispatch("FETCH_SETTLEMENT_TYPE");
    console.log('RES', res);
    const sameday_res = res.data.same_day_preference.settlement_preference.name;
    let wallet = await this.$store.dispatch("FETCH_WALLET_DETAILS");
    if (wallet.status) {
      this.walletId = wallet.data.walletid
    }
    if (res.status) {
      this.settlementType = res.data.settlement_type;
    console.log('RESPONSE', res.data);
    console.log('RESPONSE TYPE', sameday_res);
    } else {
      console.log('STATUS', res.status);
    }
    
    if (this.settlementType === 'sameday') {
      this.showDropdown = false; 
      this.samedayType.push(sameday_res);
    }


    this.isLoading = false;
  },
  methods: {
    confirmSuccessPref() {
      this.successModalCtrl = false;
      this.$router.replace({
        name: this.$route.query.onboarding ? "MainWallet" : "SettlementPreference",
      });
    },
    updateSettlementType(event, value) {
      if (value === 'sameday' && event.target.checked) {
        this.settlementType = value;
        this.showDropdown = true; 
      } else {
        this.settlementType = event.target.checked ? value : ''; 
        this.showDropdown = false; 
        if (!event.target.checked) {
          this.samedayType = []; 
        }
      }
      console.log('SETTLEMENT', this.settlementType);
    },
    toggleSamedayType(option) {
      if (this.samedayType.includes(option)) {
        this.samedayType = [];
      } else {
        this.samedayType = [option];
      }
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      if (this.settlementType === 'sameday') {
        this.showDropdown = !this.showDropdown; 
      }
    },

    async updateSettlementOption() {
      this.isLoading = true;
      console.log('SAMETYYPE', this.samedayType[0]);

      if (this.settlementType === 'sameday' && !this.samedayType[0]) {
        eventBus.$emit("trac-alert", {
          message: "Please select a Same Day Settlement option.",
        });
        this.isLoading = false;
        return; // Stop the function from proceeding
      }
      console.log('UPDATED PAYLOAD', this.settlementType, this.samedayType);

      const res = await this.$store.dispatch("UPDATE_SETTLEMENT_TYPE", {
        settlement_type: this.settlementType,
        sameday_type: this.samedayType[0] || "",
      });
      if (res.status) {
        this.successModalCtrl = true;
        this.successRes = res;
      console.log('RESPONSE PLX', res);
      } else {
        eventBus.$emit("trac-alert", {
          message: res.message,
        });
      }
      this.isLoading = false;
      console.log('CAME THIS FARRRRRR');
    },
  },
};
</script>

<style lang="scss" scoped>
.info-hold {
  .info-icon {
    width: 0.65rem;
    cursor: pointer;

    &:hover+.info-card {
      display: block;
    }
  }

  .info-card {
    top: 0%;
    font-size: 0.65rem;
    left: 105%;
    display: none;
  }
}

.text-xxs{
  font-size: 0.6rem;
}
.caret-rotate {
  transform: rotate(180deg);
}
</style>